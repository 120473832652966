import { Component } from '@angular/core';
import { UltraMenuItemComponent } from '../../../../../../shared/components/ultramarine-ui/ultra-menu-item/ultra-menu-item.component';
import { UltraMenuItemWrapperComponent } from '../../../../../../shared/components/ultramarine-ui/ultra-menu-item-wrapper/ultra-menu-item-wrapper.component';
import { fuseAnimations } from '../../../../../../../@fuse/animations';
import { Router } from '@angular/router';
@Component({
	selector: 'app-user-menu',
	templateUrl: 'user-menu.component.html',
	standalone: true,
	animations: fuseAnimations,
	imports: [
		UltraMenuItemComponent,
		UltraMenuItemWrapperComponent,
		UserMenuComponent,
	],
})
export class UserMenuComponent {
	constructor(private _r: Router) {}

	onSignOut() {
		this._r.navigate(['admin-authentication/sign-out']);
	}

	onAccountClicked() {
		this._r.navigate(['admin', 'account']);
	}
}
