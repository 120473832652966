import {
	Component,
	OnDestroy,
	OnInit,
	ViewEncapsulation,
	inject,
} from '@angular/core';
import { RouterModule } from '@angular/router';
import { Subject, takeUntil } from 'rxjs';
import { fuseAnimations } from '../../../../@fuse/animations';
import { UltraButtonComponent } from '../../../shared/components/ultramarine-ui/ultra-button/ultra-button.component';
import { UserPortalComponent } from './components/user-portal/user-portal.component';
import { UltraNavigationComponent } from '../../../shared/components/ultramarine-ui/ultra-navigation/ultra-navigation.component';
import { PopStateComponent } from '../../../shared/components/pop-state/pop-state.component';
import { MatSidenavModule } from '@angular/material/sidenav';
import { LanguagesModule } from './components/languages/languages.module';
import { MediaWatcherService } from '../../../shared/services/media-watcher.service';

@Component({
	selector: 'app-ultramarine-layout',
	templateUrl: './ultramarine-layout.component.html',
	encapsulation: ViewEncapsulation.None,
	styleUrls: ['ultramarine-layout.component.scss'],
	animations: fuseAnimations,
	standalone: true,
	imports: [
		LanguagesModule,
		PopStateComponent,
		MatSidenavModule,
		UltraNavigationComponent,
		UserPortalComponent,
		UltraButtonComponent,
		RouterModule,
	],
})
export class UltramarineLayout implements OnInit, OnDestroy {
	private _unsubscribeAll: Subject<any> = new Subject<any>();
	isScreenSmall: boolean;
	showSideMenu: boolean = true;
	public drawerMode: 'over' | 'side';
	private _mediaWatcherService = inject(MediaWatcherService);

	ngOnInit(): void {
		this._mediaWatcherService.onMediaChange$
			.pipe(takeUntil(this._unsubscribeAll))
			.subscribe(({ matchingAliases }) => {
				this.isScreenSmall = !matchingAliases.includes('md');
				if (this.isScreenSmall) {
					this.showSideMenu = false;
					this.drawerMode = 'over';
				} else {
					this.showSideMenu = true;
					this.drawerMode = 'side';
				}
			});
	}

	toggleSideMenu() {
		this.showSideMenu = !this.showSideMenu;
	}

	toggleDarkMode() {
		const bodyElement = document.body;
		if (bodyElement.classList.contains('light')) {
			bodyElement.classList.remove('light');
			bodyElement.classList.add('dark');
		} else {
			bodyElement.classList.remove('dark');
			bodyElement.classList.add('light');
		}
	}

	ngOnDestroy(): void {
		this._unsubscribeAll.next(null);
		this._unsubscribeAll.complete();
	}
}
