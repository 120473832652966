<div @fadeIn class="bg-default border rounded-md py-1">
	<span ultra-menu-item-wrapper>
		<button
			[disabled]="true"
			ultra-menu-item
			(click)="onAccountClicked()"
			[content]="'shared|user-menu|account'"
			[svgIcon]="'business'"></button>
	</span>
	<span ultra-menu-item-wrapper>
		<button
			ultra-menu-item
			[disabled]="true"
			[content]="'shared|user-menu|settings'"
			[svgIcon]="'settings'"></button>
	</span>
	<span ultra-menu-item-wrapper>
		<button
			ultra-menu-item
			(click)="onSignOut()"
			[content]="'shared|user-menu|sign-out'"
			[svgIcon]="'logout'"></button>
	</span>
</div>
