import { Overlay, OverlayRef } from '@angular/cdk/overlay';
import { TemplatePortal } from '@angular/cdk/portal';
import {
	Component,
	ElementRef,
	TemplateRef,
	ViewChild,
	ViewContainerRef,
	inject,
} from '@angular/core';
import { UserMenuComponent } from './components/user-menu.component';
import { UltraButtonComponent } from '../../../../../shared/components/ultramarine-ui/ultra-button/ultra-button.component';
import { UltraPrimaryButtonComponent } from '../../../../../shared/components/ultramarine-ui/ultra-primary/ultra-primary.component';

@Component({
	selector: 'app-user-portal',
	templateUrl: 'user-portal.component.html',
	standalone: true,
	imports: [
		UserMenuComponent,
		UltraButtonComponent,
		UltraPrimaryButtonComponent,
	],
})
export class UserPortalComponent {
	@ViewChild('anchor', { read: ElementRef }) anchor!: ElementRef;
	@ViewChild('templateRef')
	templateRef: TemplateRef<unknown>;
	templatePortal: TemplatePortal;
	overlayRef: OverlayRef;
	private _overlay = inject(Overlay);

	constructor(private _viewContainerRef: ViewContainerRef) {}
	ngAfterViewInit() {
		this.templatePortal = new TemplatePortal(
			this.templateRef,
			this._viewContainerRef,
		);
	}

	showUserMenu() {
		const positionStrategy = this._overlay
			.position()
			.flexibleConnectedTo(this.anchor)
			.withPositions([
				{
					originX: 'start',
					originY: 'bottom',
					overlayX: 'start',
					overlayY: 'top',
				},
				{
					originX: 'end',
					originY: 'bottom',
					overlayX: 'end',
					overlayY: 'top',
				},
			]);
		this.overlayRef = this._overlay.create({ positionStrategy });
		console.log(this.overlayRef);
		this.overlayRef.attach(this.templatePortal);
		const subscription = this.overlayRef
			.outsidePointerEvents()
			.subscribe(() => {
				this.overlayRef.detach();
				subscription.unsubscribe();
			});
	}
}
