<button
	class="w-full"
	ultra-primary
	(click)="click.emit()"
	color="default"
	[svgIcon]="icon()"
	[content]="title()"></button>
<!--

		<button
		class="w-full"
		ultra-primary
		(click)="onQuickActionClick()"
		color="default"
		[svgIcon]="'receipt_long'"
		[content]="'shared|quick-action|lease|title'"></button> -->
