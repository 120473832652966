import { Component } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatIconModule } from '@angular/material/icon';

import { RouterModule } from '@angular/router';
import { UltraButtonComponent } from '../ultramarine-ui/ultra-button/ultra-button.component';

@Component({
	selector: 'app-pop-state',
	templateUrl: 'pop-state.component.html',
	standalone: true,
	imports: [MatIconModule, RouterModule, MatButtonModule, UltraButtonComponent],
	host: {
		class: 'hidden sm:flex',
	},
})
export class PopStateComponent {
	constructor() {}
	goBack(): void {
		window.history.back();
	}

	goForward(): void {
		window.history.forward();
	}
}
