<!-- <fuse-loading-bar></fuse-loading-bar> -->
<mat-drawer-container class="flex-auto h-full">
	<mat-drawer
		class="w-50"
		[autoFocus]="false"
		[position]="'start'"
		[mode]="drawerMode"
		[(opened)]="showSideMenu">
		<app-ultra-navigation>
			<app-user-portal></app-user-portal>
		</app-ultra-navigation>
	</mat-drawer>
	<mat-drawer-content class="flex flex-col">
		<div
			class="flex bg-white dark:bg-neutral-900 items-center w-full h-12 px-2 z-49 border-b">
			<button
				ultra-button
				(click)="toggleSideMenu()"
				[svgIcon]="'menu'"></button>
			<app-pop-state></app-pop-state>
			<languages class="ml-auto"></languages>
			<button
				ultra-button
				(click)="toggleDarkMode()"
				[svgIcon]="'dark_mode'"></button>
		</div>
		<div class="flex flex-col flex-auto">
			<router-outlet />
		</div>
	</mat-drawer-content>
</mat-drawer-container>
