<div class="flex overflow-scroll gap-2 p-2">
	<app-quick-action
		(click)="onRent()"
		[title]="'shared|quick-action|rent|title'"
		[icon]="'car_rental'" />
	<app-quick-action
		(click)="onLease()"
		[title]="'shared|quick-action|lease|title'"
		[icon]="'receipt_long'" />
</div>
