<div class="flex flex-col justify-between h-full bg-white dark:bg-neutral-900">
	<div>
		<div
			class="flex justify-between border-b p-2 pb-4 h-20 bg-gray-100 dark:bg-[#141416]">
			<img
				class="w-fit h-7 p-1"
				src="assets/images/logo/ultramarine.png"
				alt="Logo image" />
			<ng-content></ng-content>
		</div>
		<div class="flex flex-col w-full overflow-y-auto my-1 p-1 gap-0.5">
			<div
				[routerActive]="item.link"
				(click)="item.link ? onNavigate(item) : null"
				*ngFor="let item of navigationData"
				ultra-menu-item-expandable
				[content]="item.title"
				[type]="item.type"
				[svgIcon]="item.icon">
				<ng-container *ngIf="item.children">
					<ng-container *ngFor="let item of item.children">
						<a
							[routerLink]="['../admin', item.link]"
							[routerActive]="item.link"
							class="w-full pl-4 !mx-0 max-w-full"
							*ngIf="item.type === 'basic'"
							[content]="item.title"
							[svgIcon]="item.icon"
							[disabled]="item.disabled"
							ultra-menu-item></a>
					</ng-container>
				</ng-container>
			</div>
		</div>
	</div>

	<div class="text-md text-center py-2 bg-white dark:bg-neutral-900">
		<div>
			<app-quick-actions></app-quick-actions>
		</div>
		<div class="border-t">
			<span class="text-gray-500">Split Second Connect.</span>
			<span class="ml-1 text-gray-500">{{ currentYear }}</span>
		</div>
	</div>
</div>
